var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "预警金额>=", prop: "warnning_deposit" } },
            [
              _c("el-input", {
                staticStyle: { width: "110px" },
                on: { blur: _vm.changeYuJingJine },
                model: {
                  value: _vm.form.warnning_deposit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "warnning_deposit", $$v)
                  },
                  expression: "form.warnning_deposit",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "area_id" } },
            [
              _c("SaleArea", {
                ref: "saleArea",
                on: { "set-sale-select-id": _vm.setSaleSelectId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "class_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { clearable: "", placeholder: "请选择客户等级" },
                  model: {
                    value: _vm.form.class_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "class_id", $$v)
                    },
                    expression: "form.class_id",
                  },
                },
                _vm._l(_vm.clientLevelList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "selectDC" },
                on: { "select-val": _vm.selectValAlldataAll },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "", prop: "" } }),
          _c("el-form-item", { attrs: { label: "", prop: "" } }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", stripe: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "index", width: "50" },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.prop == "credit_amount" ||
                        item.prop == "arrears_amount"
                          ? _c(
                              "div",
                              {
                                staticClass: "underline",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleYucun(item.prop, row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                            )
                          : _c("div", [
                              _vm._v(" " + _vm._s(row[item.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }