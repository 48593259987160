<template>
  <!-- NAME[epic=预警] 预存代收款 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="预警金额>=" prop="warnning_deposit">
        <el-input
          v-model="form.warnning_deposit"
          style="width: 110px"
          @blur="changeYuJingJine"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="area_id">
        <SaleArea
          ref="saleArea"
          @set-sale-select-id="setSaleSelectId"
        ></SaleArea>
      </el-form-item>
      <el-form-item label="" prop="class_id">
        <el-select
          v-model="form.class_id"
          clearable
          placeholder="请选择客户等级"
          style="width: 160px"
        >
          <el-option
            v-for="item in clientLevelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-val="selectValAlldataAll"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item label="" prop=""></el-form-item>
      <el-form-item label="" prop=""></el-form-item>
    </el-form>
    <el-table v-loading="loading" border stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.prop == 'credit_amount' || item.prop == 'arrears_amount'"
            class="underline"
            @click="handleYucun(item.prop, row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      style="text-align: center"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import SaleArea from '@/baseComponents/saleAreaSelectTree'
  import { getClientLevelList } from '@/api/setPrice'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile, postAction } from '@/api/Employee'

  export default {
    name: 'YuCunKuanShouKuan',
    components: { SaleArea, ClientSearch },
    data() {
      return {
        form: {
          warnning_deposit: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        loading: false,
        clientLevelList: [],
        total: 0,
        list: [],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '等级',
            prop: 'class_name',
            width: '',
          },
          {
            order: 3,
            label: '地址',
            prop: 'address',
            width: '',
          },
          {
            order: 4,
            label: '老板姓名',
            prop: 'boss',
            width: '',
          },
          {
            order: 5,
            label: '电话',
            prop: 'mobile',
            width: '',
          },
          {
            order: 6,
            label: '预存代收款合计',
            prop: 'zong_amount',
            width: '',
          },
          {
            order: 7,
            label: '1.预存款代收款',
            prop: 'credit_amount',
            width: '',
          },
          {
            order: 7,
            label: '2.预存货代收款',
            prop: 'arrears_amount',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.initSelect()
      this.fetchData()
    },
    methods: {
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleExport() {
        downloadFile(
          '/customerAdmin/warning/deposit-list-export',
          '预存代收款.xlsx',
          this.form
        )
      },
      async changeYuJingJine() {
        let res = await postAction('/setAdmin/config/set', {
          cfg_name: 'warnning_deposit',
          cfg_value: this.form.warnning_deposit,
        })
      },
      async fetchData() {
        this.loading = true
        try {
          let res = await postAction(
            '/customerAdmin/warning/deposit-list',
            this.form
          )
          this.list = res.data
          this.form.warnning_deposit = res.msg
          this.total = Number(res.totalCount)
          this.loading = false
        } catch (err) {
          console.log('接口调用错误', err)
          this.loading = false
        }
      },
      initSelect() {
        // 获取客户等级下拉
        getClientLevelList().then((res) => {
          if (res.code == 200) this.clientLevelList = res.data
        })
      },
      selectValAlldataAll(val) {
        this.form.cust_id = val
      },
      setSaleSelectId(val) {
        this.form.area_id = val
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleYucun(prop, row) {
        console.log('prop,row', prop, row)
        if (prop == 'credit_amount') {
          // 预存款
          this.$router.push({
            path: '/movablepin/predeposit/depositreceipt',
            query: { is_left: '1', name: row.cust_name, custId: row.cust_id },
          })
        } else {
          // 预存货
          // /movablepin/preInventory/clientDoc
          this.$router.push({
            path: '/movablepin/preInventory/clientDoc',
            query: { is_left: '1', name: row.cust_name, custId: row.cust_id },
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
